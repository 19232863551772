@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add custom CSS here */
@media (orientation: landscape) {
    .landscape-warning {
        @apply block;
        /* Show warning or adjust layout */
    }

    .main-content {
        @apply hidden;
        /* Hide the main content or adjust */
    }
}

@media (orientation: portrait) {
    .landscape-warning {
        @apply hidden;
    }

    .main-content {
        @apply block;
    }
}